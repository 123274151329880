import React, { useState, useEffect} from 'react';
import '../styles/Header.scss';

const Header = () => {
    return (
      <div className='header'>
        <div className='header_text'>AMMAR JIVRAJ</div>
      </div>
    );
  };

  export default Header;


